<script setup>
import { defineProps } from "vue";
const props = defineProps(['isDarkMode', 'locale']);

</script>

<template>
  <div class="comingsoon"  :class="{ 'dark-mode': props.isDarkMode, 'light-mode': !props.isDarkMode }">
    <h1>{{ $t('coming_soon') }}</h1>
    <p>{{ $t('coming_soon_text') }}</p>
  </div>
</template>